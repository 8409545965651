<template>
    <NuxtLayout name="catalog">
        <div class="page-container mt-4 mb-12">
            <AtomsTextHeader class="uppercase">{{ $t('404 - Stránka nenalezena') }}</AtomsTextHeader>
            {{ $t('Zkontrolujte, zda není v zadané adrese překlep. Pokud je v pořádku, pravděpodobně se bude jednat o zrušenou stránku.') }}
            
            <TemplatesNotFound />
        </div>
    </NuxtLayout>
</template>
<script setup>

const event = useRequestEvent()

setResponseStatus(event, 404);

useHead({
  title: `Stránka nenalezena | SnubníPrsteny4u.eu`
});

</script>